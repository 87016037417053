<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <a-tooltip placement="top">
            <template slot="title"><span>Add Annual Leave Date</span></template>
            <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-annual-leave-date-modal @click="$bvModal.show('add-update-annual-leave-date-modal'), resetForm()">
              <i class="fa fa-plus"/> Add Annual Leave Date
            </a>
          </a-tooltip>
          <table class="table table-sm table-bordered text-center" id="annual_leave_dates_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(annual_leave_date, index) in annual_leave_dates" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ annual_leave_date.date }}</td>
              <td>{{ annual_leave_date.description }}</td>
              <td>{{ annual_leave_date.status === 1 ? 'Active' : 'Inactive' }}</td>
              <td>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(annual_leave_date.id), $bvModal.show('add-update-annual-leave-date-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
                <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(annual_leave_date.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="annual_leave_dates.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
      <add-update-annual-leave-date
        :annual_leave_date="annual_leave_date"
        :btnEdit="btnEdit"
        @getAnnualLeaveDates="getAnnualLeaveDates"
        @resetForm="resetForm">
      </add-update-annual-leave-date>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateAnnualLeaveDate from '@/views/annualLeaveDate/modal/addUpdateAnnualLeaveDate'
import $ from 'jquery'

export default {
  name: 'List',
  components: {
    Empty,
    addUpdateAnnualLeaveDate,
  },
  data() {
    return {
      annual_leave_dates: [],
      annual_leave_date: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getAnnualLeaveDates()
  },
  methods: {
    getAnnualLeaveDates() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/annual-leave-dates')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.annual_leave_dates = response.data.annual_leave_dates
          setTimeout(function () {
            $('#annual_leave_dates_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.annual_leave_date = {}
      this.btnEdit = true
      apiClient.get('api/annual-leave-dates/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.annual_leave_date = response.data.annual_leave_date
        }
      })
    },
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/annual-leave-dates/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#annual_leave_dates_list').DataTable().destroy()
              this.getAnnualLeaveDates()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.annual_leave_date = {}
    },
  },
}
</script>

<style scoped>

</style>
