<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-annual-leave-date-modal" title="Annual Leave Date Add/Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="form-row row">
            <div class="col-md-6 col-sm-6 col-lg-6 center">
              <label for="date" class="col-form-label col-form-label-sm"> Date <span class="custom-required">*</span></label>
              <datepicker :format="customDateFormat" v-validate="{ rules: { required:  true } }" :class="errors.has('date') ? 'error-border-color' : ''"   id="requested_at" v-model="annual_leave_date.date"  name="date" placeholder="Select date" ></datepicker>
              <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('date')">Date required</div>
            </div>
          </div>
          <div class="form-row row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="description" class="col-form-label col-form-label-sm"> Description <span class="custom-required">*</span></label>
              <input type="text" v-model="annual_leave_date.description" v-validate="{ required: true}" id="description" name="description" class="form-control form-control-sm" placeholder="Enter description"/>
              <div class="invalid-feedback">Description is required</div>
            </div>
          </div>
          <div class="form-row row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="status" class="col-form-label col-form-label-sm"> Status <span class="custom-required">*</span></label>
              <select v-model="annual_leave_date.status" v-validate="'required'" id="status" name="status" class="form-control form-control-sm">
                <option :value="''">Select status</option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </select>
              <div class="invalid-feedback">Status is required</div>
            </div>
          </div>
          <div class="form-row mt-3 mb-3 row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" v-if="!this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="primary" v-if="this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-annual-leave-date-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import $ from 'jquery'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'addUpdateAnnualLeaveDate',
  props: ['annual_leave_date', 'btnEdit'],
  components: { Datepicker },
  data() {
    return {
      validation_errors: {},
      show: false,
      loader: false,
    }
  },
  mounted() { },
  methods: {
    customDateFormat(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/annual-leave-dates', this.annual_leave_date).then(response => {
            this.loader = false
            if (response.data.error) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#annual_leave_dates_list').DataTable().destroy()
              this.$emit('resetForm')
              this.$emit('getAnnualLeaveDates')
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.put('api/annual-leave-dates/' + this.annual_leave_date.id, this.annual_leave_date)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                $('#annual_leave_dates_list').DataTable().destroy()
                this.$bvModal.hide('add-update-annual-leave-date-modal')
                this.$emit('getAnnualLeaveDates')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
</style>
